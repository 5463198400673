<template>
  <div v-anim-ctrl="{ top: 500, init: null }" class="warp anim-box">
    <img
      class="bg"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/ae6a4855-6a6b-4e88-ab7b-4508302b9a59.png"
    />
    <img
      class="item item1 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/342adebf-f264-455c-b5eb-0216ed82774a.png"
    />
    <img
      class="item item2 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/632da0df-41e9-409b-8ad0-0a8321c6a25c.png"
    />
    <img
      class="item item3 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/f3cde9b5-cd23-4458-85f0-a01ce08e6301.png"
    />
    <img
      class="item item4 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/37a32c2f-d88c-4be1-94b1-ee61cdd69756.png"
    />
    <img
      class="item item5 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/499116ef-5782-44bc-b925-24a1fd532cb6.png"
    />
    <img
      class="item item6 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/2dc85fc3-6643-4ef7-8e1c-8fe9b5050c45.png"
    />
    <img
      class="item item7 base-teature"
      src="https://zyb-zhike-file.cdnjtzy.com/mis/14f63424-1a55-44bf-8817-88695a57c9bf.png"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AsyncManage',
  setup() {}
})
</script>

<style lang="less" scoped>
.warp {
  width: 468px;
  height: 468px;
  margin: 109px auto 126px auto;
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
  }
  .item {
    position: absolute;
  }
  .item1 {
    top: 105px;
    left: -350px;
    width: 440px;
    height: 166px;
  }
  .item2 {
    top: -53px;
    left: -215px;
    width: 443px;
    height: 166px;
    transition-delay: 0.5s;
  }
  .item3 {
    top: -53px;
    right: -187px;
    width: 403px;
    height: 166px;
    transition-delay: 1s;
  }
  .item4 {
    top: 105px;
    right: -346px;
    width: 435px;
    height: 166px;
    transition-delay: 1.5s;
  }
  .item5 {
    bottom: -5px;
    right: -272px;
    width: 403px;
    height: 166px;
    transition-delay: 2s;
  }
  .item6 {
    bottom: -136px;
    left: 97px;
    width: 272px;
    height: 212px;
    transition-delay: 2.5s;
  }
  .item7 {
    bottom: -5px;
    left: -159px;
    width: 291px;
    height: 166px;
    transition-delay: 3s;
  }
}
.base-teature {
  position: absolute;
  opacity: 0;
  transform: translateY(100px);
  background-size: contain;
  background-repeat: no-repeat;
  transition-property: none;
  transition-duration: 1s;
}
.anim-box[data-animationed] {
  .base-teature {
    transform: translateY(0);
    opacity: 1;
    transition-property: all;
  }
}
</style>
